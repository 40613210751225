<template>
    <div class="home">
        <div class="home-wrapper container">
            <div class="text">
                <h1>{{ $t('discovering_gem') }}</h1>
                <p class="desc">{{ $t('know_and_understand') }}</p>

                <div class="buttons">
                    <button class="primary" @click="getStarted">{{ $t('get_started') }}</button>
                    <button @click="$emit('move', 'hiw')" class="watch">{{ $t('watch_video') }}</button>
                </div>
            </div>

            <div class="image">
                <img :src="require('@/assets/img/home_banner.png')" alt="Home Banner">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        getStarted () {
            this.$router.push('sign-up')
        }
    }
}
</script>

<style lang="scss" scope>
.home {
    display: flex;
    
    @include desktop {
        background-image: url('~@/assets/img/home_upperbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .home-wrapper {
        display: flex;
        padding: 75px 20px;
        
        @include desktop {
            width: 1200px;
            padding: 180px 20px;
        }

        .text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: #221E1F;
            margin: auto;
            
            h1 {
                font-family: 'Crimson Pro', sans-serif;
                font-weight: 700;
                margin-bottom: 20px;
                font-size: 22px;
                text-align: center;

                @include desktop {
                    width: 515px;
                    font-size: 44px;
                    text-align: left;
                }

            }

            .desc {
                font-size: 16px;
                margin-bottom: 65px;
                text-align: center;

                @include desktop {
                    width: 550px;
                    margin-bottom: 110px;
                    text-align: left;
                }
            }

            .buttons {
                display: flex;
                margin: auto;

                @include desktop {
                    margin: unset;
                    margin-left: 3em;
                }

                .primary {
                    margin-right: 20px;
                }

                .watch {
                    color: #00AEEF;
                    background-color: transparent;
                    text-decoration: underline;
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    transform: scale(var(--ggs,1));
                    padding-left: 25px;

                    &::before {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        width: 0;
                        height: 10px;
                        border-top: 9px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-left: 15px solid;
                        top: 11px;
                        left: 0px
                    }
                }
            }
        }

        .image {
            display: none;
            padding-left: 5em;
            flex: 1 0;

            @include desktop {
                display: block;
            }

            img {
                width: 500px;
                height: 376px;
            }
        }
    }
}
</style>